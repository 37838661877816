.cards {
  padding: 2rem;
  background: #fff;
}

h1 {
  text-align: center;
  margin-bottom: 1.5rem;
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1040px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 1rem;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 1.5rem;
  min-width: 15rem;
  background-color: #D90000;
  margin-bottom: 1rem;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 1.5rem;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__link:hover {
  background-color: #FF3300;
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 1.5rem;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__info {
  padding: 1rem 2.5rem 2.5rem;
  text-align: center;
}

.cards__item__task {
  color: #fff;
  font-size: 0.9rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

.cards__item__text {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1rem;
  margin-bottom: 2rem;
}

.cards__item__date {
  color: #fff;
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

.cards__item__header {
  color: #000;
  font-size: 0.8rem;
  text-align: justify;
}

.cards__item__time {
  color: #fff;
  font-size: 0.9rem;
  line-height: 1rem;
}

@media only screen and (min-width: 1350px) {
  .content__blog__container {
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}