.panel {
    display: flex;
    height: auto;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: #000;
    margin-top: 2rem;
}

.panelButton {
    display: flex;
    margin: auto;
    margin-bottom: 2rem;
    padding: 0.9rem;
    background-color: transparent;
    border-radius: 1rem;
    border: 0.1rem solid #d90000;
}

.panelButton:hover {
    background-color: #d90000;
    color: #fff;
}

.panelItem {
    border: 1px solid #000;
    text-align: center;
    margin: auto;
    width: auto;
    max-width: 90%;
    height: auto;
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.panelItemToggle {
    display: inline-flex;
    align-items: flex-start;
    margin-left: 15px;
    margin-top: 15px;
    font-size: 20px;
    cursor: pointer;
}

.panelItemToggle button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid #d90000;
    background-color: transparent;
    border-radius: 9px;
}

.panelDeleteButton {
    padding: 0.1rem 0.5rem 0.1rem;
}

.panelDeleteButton:hover {
    padding: 0.1rem 0.5rem 0.1rem;
    background-color: #d90000;
}

.changeRole {
    padding: 0.1rem 0.5rem 0.1rem;
}

.changeRole:hover {
    padding: 0.1rem 0.5rem 0.1rem;
    background-color: #d90000;
}

.panelItemToggle button:hover {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid #d90000;
    background-color: #d90000;
    border-radius: 9px;
}

.panelItemHeader {
    display: inline-flex;
    margin-left: 1rem;
    margin-bottom: 2rem;
}

.grid-ledenlijst-admin {
    display: grid;
    grid: 3rem / auto auto auto auto auto;
    grid-gap: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: transparent;
}

.grid-ledenlijst-general {
    display: grid;
    grid: 3rem / auto auto auto;
    grid-gap: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: transparent;
}

.grid-ledenlijst-admin>div,
.grid-ledenlijst-general>div {
    background-color: #d90000;
    text-align: center;
    font-size: auto;
    text-align: center;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.grid-ledenlijst-items {
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-size: 1rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}

.grid-taakverdelingtitel {
    display: grid;
    grid: 3rem / auto;
    margin-bottom: 1rem;
}

.grid-export {
    display: grid;
    grid: 3rem / auto;
    justify-self: center;
    margin-bottom: 1rem;
}

.grid-export button {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid #d90000;
    background-color: transparent;
    border-radius: 9px;
}

.grid-export button:hover {
    background-color: #d90000;
    color: #fff;
}

.grid-taakverdeling {
    display: grid;
    /* grid: auto / 11% 17% 17% 17% 17% 17%; */
    grid-gap: 0.5rem;
    background-color: transparent;
    margin-bottom: 0.2rem;
}


.grid-taakverdeling>div {
    background-color: #d90000;
    text-align: center;
    font-size: auto;
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.grid-taakverdeling-items {
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-size: 1rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    white-space: pre-line;
    overflow: auto;
    max-height: 100px;
}

.panelTakenDropdown select {
    width: 60%;
    margin-bottom: 2rem;
}

.panelTakenList {
    display: grid;
    padding: 0.2rem;
    margin: 0.2rem;
    /* border: 1px solid black; */
}

.failedMsg {
    color: #d90000;
    display: flex;
    height: auto;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.successMsg {
    color: green;
    display: flex;
    height: auto;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.bodyList {
    background-color: bisque;
}

.settingsInput {
    width: 30rem;
}

@media only screen and (max-width: 1100px) {
    .panel {
        width: auto;
        max-width: 90%;
    }
}