* {
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
}

.home {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.taakverdeling,
.agenda {
  display: flex;
  height: auto;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #000;
  font-size: 50px;
}

#calendarAdmin {
  border: 1px solid #000;
  text-align: center;
  margin: auto;
  width: 50%;
  max-width: 50%;
  height: auto;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

}

.taakverdelingItem {
  border: 1px solid #000;
  text-align: center;
  margin: auto;
  width: auto;
  max-width: 60%;
  height: auto;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 1100px) {
  .taakverdelingItem {
    width: auto;
    max-width: 90%;
  }
}

.taakverdelingItem h1 {
  text-align: center;
  display: block;
  margin-top: 1rem;
}

.taakverdelingItemNormal {
  color: #000;
  text-align: center;
  display: block;
  margin-top: 1rem;
}

.taakverdelingItemReq {
  color: #d90000;
  text-align: center;
  display: block;
  margin-top: 1rem;
}

.taakverdelingItem input,
.taakverdelingItem select {
  width: 50%;
  padding: 0.2rem 0.5rem;
  margin: 0.3rem 0;
  border: 0.1rem solid #000;
  box-sizing: border-box;
  display: inline;
}

.taakverdelingItem textarea {
  resize: none;
  width: 50%;
  height: 5rem;
  display: inline;
}

@media only screen and (max-width: 1000px) {

  .taakverdelingItem input,
  .taakverdelingItem select,
  .taakverdelingItem textarea {
    width: 80%;
  }
}

.taakverdelingItemToggle {
  display: inline-flex;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 15px;
  font-size: 20px;
  cursor: pointer;
}

.taakverdelingItemToggle button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid #d90000;
  background-color: transparent;
  border-radius: 9px;
}

.taakverdelingItemToggle button:hover {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid #d90000;
  background-color: #d90000;
  border-radius: 9px;
}

.taakverdelingItemHeader {
  display: inline-flex;
  margin-left: 1rem;
}

#inputfield {
  margin-left: 1rem;
  margin: auto;
  margin-top: 0.5rem;
  display: block;
}

.adminPanelButton {
  display: flex;
  margin: auto;
  margin-bottom: 2rem;
  padding: 0.9rem;
  background-color: transparent;
  border-radius: 1rem;
  border: 0.1rem solid #d90000;
}

.adminPanelButton:hover {
  background-color: #d90000;
  color: #fff;
}

.errorField {
  color: #fff;
  background-color: #d90000;
  text-align: center;
  margin-bottom: 1rem;
}

.adminTaskTime {
  display: inline-flex;
}

.grid {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
}

.grid div {
  border: 0.1rem solid black
}

.indelen {
  font-size: smaller;
  padding-left: 1rem;
}

.indelen button {
  margin: 0.3rem;
  padding: 0.1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.2rem solid green;
  background-color: transparent;
  border-radius: 0.3rem;
}

.indelen button:hover {
  background-color: green;
}

.delete {
  font-size: smaller;
  padding-left: 1rem;
}

.delete button {
  margin: 0.3rem;
  padding: 0.1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.2rem solid red;
  background-color: transparent;
  border-radius: 0.3rem;
}

.delete button:hover {
  background-color: red;
}

.calendarDDI {
  width: 60%;
  margin: auto;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 1100px) {
  .calendarDDI {
    width: auto;
    max-width: 100%;
    margin-bottom: 5rem;
  }
}

.inform {
  text-decoration: underline;
  font-style: italic;
  margin-bottom: 1rem;
}

.checkbox {
  list-style: none;
  display: inline-block;
  margin-top: 1rem;
  padding-right: 1rem;
}

.niet_gevonden {
  text-align: center;
}

.success {
  text-align: center;
  margin-bottom: 1rem;
  transition: opacity 1s ease;
}

.error {
  text-align: center;
  margin-bottom: 1rem;
}

.taakentijd {
  display: inline-block;
  width: 60%;
}

.firstLogin h2 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: #d90000;
  font-style: italic;
}

.taakverdelingItem .selectNumber {
  width: 10%;
  display: flex;
  margin: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.attendees {
  margin: auto;
  width: 60%;
  font-style: italic;
  font-size: smaller;
}

.legenda {
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: center;
}

.legendaItem {
  padding: 0.50rem;
  margin-right: 1rem;
  font-size: smaller;
  border-radius: 0.5rem;
}

.legendaUitleg {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 60%;
  margin-bottom: 0.2rem;
  font-size: smaller;
}

.addPerson {
  display: inline-flex;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 15px;
  font-size: 20px;
  cursor: pointer;
}

.addPerson button {
  font-size: smaller;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #d90000;
  background-color: transparent;
  border-radius: 9px;
}

.addPerson button:hover {
  font-size: smaller;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #d90000;
  background-color: #d90000;
  border-radius: 9px;
}