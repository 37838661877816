.navbar {
  background: #cd171a;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0.8rem;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 1rem;
}

.nav-item {
  height: 4rem;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 0.3rem solid #fff;
  transition: all 0.2s ease-out;
  color: #fff;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 10rem;
    position: absolute;
    top: 4rem;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 999;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
    padding: 1rem;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 0.7rem 1rem;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}