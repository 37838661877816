.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  height: 70vh;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  background-color: #fff;
  color: #d90000;
}

input[type="text"],
input[type="password"] {
  height: 2rem;
  border: 0.1rem solid #000;
  color: #000;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

input[type="submit"] {
  margin-top: 2rem;
  cursor: pointer;
  font-size: 1rem;
  background: #fff;
  border: 0.1rem solid #d90000;
  color: #d90000;
  padding: 0.8rem 2rem;
}

input[type="submit"]:hover {
  background: #d90000;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: #fff;
  padding: 2rem;
  min-width: 20rem;
  box-shadow: 0 0.5rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.6rem 20rem 0 rgba(0, 0, 0, 0.19);
  border: 0.1rem solid #d90000;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 120%;
}

.success {
  color: green;
  font-size: 120%;
}

.title {
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem;
}

.linkddi {
  margin-top: 1rem;
}

.wachtwoordvergeten {
  margin-top: 2rem;
}

.panelDeleteButton {
  margin-top: 2rem;
  cursor: pointer;
  font-size: 1rem;
  background: #fff;
  border: 0.1rem solid #d90000;
  color: #d90000;
  padding: 0.8rem 2rem;
}

.panelDeleteButton:hover {
  background: #d90000;
  color: #fff;
}